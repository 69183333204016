body, html {
  margin: 0;
  background: rgb(40,40,40);
  background: radial-gradient(circle, rgba(40,40,40,1) 0%, rgba(13,13,13,1) 100%); 
  overflow-x: hidden;
  position: relative;
  cursor: none ;
}
:root {
  --color-cursor: 201, 136, 15;
  --cursor-outline-shade: 0.3;
  --cursor-size: 10px;
  --cursor-outline-size: 12px;
}
body::-webkit-scrollbar {
	width: 5px;
	background-color: #252525;
}

body::-webkit-scrollbar-track {
	width: 5px;
	border-radius: 5px;
}

body::-webkit-scrollbar-thumb {
	background: #c9880f;
	width: 5px;
	border-radius: 5px;
}
#cursor-dot,
#cursor-dot-outline {
  z-index: 999;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
}

#cursor-dot {
  width: var(--cursor-size);
  height: var(--cursor-size);
  background-color: rgba(var(--color-cursor), 1);
}

#cursor-dot-outline {
  width: var(--cursor-outline-size);
  height: var(--cursor-outline-size);
  background-color: rgba(var(--color-cursor), var(--cursor-outline-shade));
}
